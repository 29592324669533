import { PipelineNode, PipelineNodeRelationshipDenormalizedField, PipelineNodeRelationshipORM, PipelineNodeRelationshipParentLookup } from "@models/pipelineNode";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { invalidateMissionControlDataFlowData, invalidatePipelineNodeRelationships, invalidatePipelineNodes, useIsInDraftMode, usePipelineNodeRelationship, usePipelineNodes } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import PipelineNodeSelector from "./PipelineNodeSelector.component";
import AsyncButton from "@components/button/AsyncButton.component";
import Dropdown, { Option } from "@components/form/Dropdown.component";
import { pipeline } from "stream";
import produce from "immer";
import { isValidRelationshipTarget } from "@services/modeling.service";
import { Pane, PaneContent, PaneFooter } from "@pages/PageStructure.component";
import { useRouteBlocker } from "@services/routing.service";
import DeleteButton from "@components/button/DeleteButton.component";
import { requireConfirmation } from "@services/alert/alert.service";
import { DraftOnly } from "@components/project/DraftModeRequired.component";


interface ParentLookupComponentProps {
    childName: string;
    parentName: string;
    logicGate: string;
    parentLookups: PipelineNodeRelationshipParentLookup[];
    onChangeLogicGate: (newGate: string) => any;
    onChangeParentLookups: (newLookups: PipelineNodeRelationshipParentLookup[]) => any;
    childColumnOptions: Option[];
    parentColumnOptions: Option[];
}

export const ParentLookupComponent = (props: ParentLookupComponentProps) => {
    const onChangeLookupChildColumn = useCallback((idx: number, column_id: string) => {
        const newLookups = produce(props.parentLookups, draft => {
            draft[idx].child_field_id = column_id;
        });
        props.onChangeParentLookups(newLookups);
    }, [props.onChangeParentLookups, props.parentLookups]);

    const onChangeLookupParentColumn = useCallback((idx: number, column_id: string) => {
        const newLookups = produce(props.parentLookups, draft => {
            draft[idx].parent_field_id = column_id;
        });
        props.onChangeParentLookups(newLookups);
    }, [props.onChangeParentLookups, props.parentLookups]);

    const addLookup = useCallback(() => {
        const newLookups = produce(props.parentLookups, draft => {
            draft.push({
                parent_field_id: '',
                child_field_id: '',
            });
        });
        props.onChangeParentLookups(newLookups);
    }, [props.onChangeParentLookups, props.parentLookups]);

    const removeLookup = useCallback((idx: number) => {
        const newLookups = produce(props.parentLookups, draft => {
            draft.splice(idx, 1);
        });
        props.onChangeParentLookups(newLookups);
    }, [props.onChangeParentLookups, props.parentLookups]);

    const inDraftMode = useIsInDraftMode();

    return <>
        <div className="ps-2 pt-2 pe-2">
            <Form.Check
                disabled={!inDraftMode}
                type="switch"
                id="custom-switch"
                label="Match all"
                checked={props.logicGate === 'AND'}
                onChange={(e) => props.onChangeLogicGate(e.target.checked ? 'AND' : 'OR')}
            />
        </div>
        
        
        {props.parentLookups.map((pl, idx) => {
            return <div className="border-bottom p-2" style={{position: 'relative'}}>
                <DraftOnly>
                    <div style={{position: 'absolute', top: '0px', right: '0px'}} className="p-2">
                        <button className="icon-button"  onClick={() => removeLookup(idx)}>
                            <i className="mdi mdi-close-thick"></i>
                        </button>
                    </div>
                </DraftOnly>
                
                
                <Form.Group className="mb-1">
                    <Form.Label className="small">
                        {idx > 0 && (
                            <><u>{props.logicGate.toLowerCase()}</u> this </>
                        )}
                        {idx == 0 && (
                            <>This </>
                        )}
                        column in <strong>{props.childName}</strong>
                    </Form.Label>
                    <Dropdown
                        disabled={!inDraftMode}
                        options={props.childColumnOptions}
                        selected={pl.child_field_id}
                        onChange={(newVal: string) => {
                            onChangeLookupChildColumn(idx, newVal)
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="small">...is equal to this column in <strong>{props.parentName}</strong></Form.Label>
                    <Dropdown
                        disabled={!inDraftMode}
                        options={props.parentColumnOptions}
                        selected={pl.parent_field_id}
                        onChange={(newVal: string) => {
                            onChangeLookupParentColumn(idx, newVal)
                        }}
                    />
                </Form.Group>
            </div>
                
        })}
        <DraftOnly>
            <div className="w-100 d-block">
            <button className="btn btn-default" onClick={addLookup}>
                <i className="mdi mdi-plus-circle"></i> Add Another
            </button>
            </div>
        </DraftOnly>
        
        
    </>
}

interface DenormalizedFieldsComponentProps {
    parentColumnOptions: Option[];
    denormalizedFields: PipelineNodeRelationshipDenormalizedField[];
    onChangeDenormalizedFields: (newFields: PipelineNodeRelationshipDenormalizedField[]) => any;
    parentName: string;
    childName: string;
}

export const DenormalizedFieldComponent = (props: DenormalizedFieldsComponentProps) => {
    const inDraftMode = useIsInDraftMode();
    const addField = useCallback(() => {
        const newFields = produce(props.denormalizedFields, draft => {
            draft.push({
                parent_field_id: '',
                output_field_name: '',
            });
        });

        console.log('Denormalized fields should be:', newFields);

        props.onChangeDenormalizedFields(newFields);
    }, [props.onChangeDenormalizedFields, props.denormalizedFields]);

    const removeField = useCallback((idx: number) => {
        const newFields = produce(props.denormalizedFields, draft => {
            draft.splice(idx, 1);
        });
        props.onChangeDenormalizedFields(newFields);
    }, [props.onChangeDenormalizedFields, props.denormalizedFields]);

    const onChangeParentColumn = useCallback((fieldIdx: number, newVal: string) => {
        const newFields = produce(props.denormalizedFields, draft => {
            draft[fieldIdx].parent_field_id = newVal;
        });

        props.onChangeDenormalizedFields(newFields);
    }, [props.onChangeDenormalizedFields, props.denormalizedFields])

    const onChangeChildColumn = useCallback((fieldIdx: number, newVal: string) => {
        const newFields = produce(props.denormalizedFields, draft => {
            draft[fieldIdx].output_field_name = newVal;
        });

        props.onChangeDenormalizedFields(newFields);
    }, [props.onChangeDenormalizedFields, props.denormalizedFields])

    return <>
        {props.denormalizedFields.map((dn, idx) => {
            return <div className="border-bottom p-2" style={{position: 'relative'}}>
                <DraftOnly>
                    <div style={{position: 'absolute', top: '0px', right: '0px'}} className="p-2">
                        <button className="icon-button" onClick={() => removeField(idx)}>
                            <i className="mdi mdi-delete"></i>
                        </button>
                    </div>
                </DraftOnly>
                
                <Form.Group className="mb-1">
                    <Form.Label className="small">
                        Pull this column in <strong>{props.parentName}</strong>
                    </Form.Label>
                    <Dropdown
                        disabled={!inDraftMode}
                        options={props.parentColumnOptions}
                        selected={dn.parent_field_id}
                        onChange={(newVal: string) => {
                            onChangeParentColumn(idx, newVal)
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="small">
                        ...into a column in <strong>{props.childName}</strong> called
                    </Form.Label>
                    <Form.Control disabled={!inDraftMode} onChange={(e) => onChangeChildColumn(idx, e.target.value)} value={dn.output_field_name}/>
                </Form.Group>
            </div>
        })}
        <DraftOnly>
            <div className="w-100 d-block">
                <button className="btn btn-default" onClick={addField}>
                    <i className="mdi mdi-plus-circle"></i> Add Another
                </button>
            </div>
        </DraftOnly>
        
    </>
    
}

interface Props {
    pipelineNode: PipelineNode;
    onSave?: (relationshipId: string) => any;
    relationshipId?: string;
    onDelete?: (relationshipId: string) => any;
}

const PipelineNodeRelationshipEditor = ({ pipelineNode, onSave, relationshipId, onDelete }: Props) => {
    const [otherPipelineNodeId, setOtherPipelineNodeId] = useState('');
    const [relationshipType, setRelationshipType] = useState('BELONGS_TO');
    const [savingRelationship, setSavingRelationship] = useState(false);
    const [lookupLogicGate, setLookupLogicGate] = useState('AND');
    const [parentLookups, setParentLookups] = useState<PipelineNodeRelationshipParentLookup[]>([]);
    const [denormalizedFields, setDenormalizedFields] = useState<PipelineNodeRelationshipDenormalizedField[]>([]);

    const [parentName, setParentName] = useState('');
    const [childName, setChildName] = useState('');
    const [childColumnOptions, setChildColumnOptions] = useState<Option[]>([]);
    const [parentColumnOptions, setParentColumnOptions] = useState<Option[]>([]);

    const [foreignKeyName, setForeignKeyName] = useState('');

    const existingRelationship = usePipelineNodeRelationship(relationshipId ? relationshipId : '');

    const [relationshipName, setRelationshipName] = useState('');
    const [relationshipDescription, setRelationshipDescription] = useState('');
    const nodes = usePipelineNodes();

    const deleteRelationship = useCallback(async () => {
        if (relationshipId) {
            const confirmation = await requireConfirmation('Are you sure you want to delete this relationship?', 'Delete Relationship', 'Delete');
            if (confirmation) {
                await PipelineNodeRelationshipORM.deleteById(relationshipId as string);
                invalidatePipelineNodeRelationships();
                invalidatePipelineNodes();
                invalidateMissionControlDataFlowData();
                if (onDelete) {
                    onDelete(relationshipId);
                }
            }

            
        }
    }, [relationshipId, onDelete])


    useEffect(() => {
        if (existingRelationship.data) {
            setLookupLogicGate(existingRelationship.data.parent_lookup_logic_gate);
            setParentLookups(existingRelationship.data.parent_lookups);
            if (existingRelationship.data.child_node_id == pipelineNode.id) {
                setOtherPipelineNodeId(existingRelationship.data.parent_node_id);
                setRelationshipType('BELONGS_TO');
            } else {
                setOtherPipelineNodeId(existingRelationship.data.child_node_id);
                setRelationshipType('HAS_MANY');
            }

            setDenormalizedFields(existingRelationship.data.denormalized_fields || []);
            setRelationshipName(existingRelationship.data.name);
            setRelationshipDescription(existingRelationship.data.description);
            setForeignKeyName(existingRelationship.data.child_foreign_key_name);
        } else if (!relationshipId) {
            // Reset
            setLookupLogicGate('AND');
            setParentLookups([]);
            setOtherPipelineNodeId('');
            setRelationshipType('BELONGS_TO');
            setDenormalizedFields([]);
            setRelationshipName('');
            setRelationshipDescription('');
            setForeignKeyName('');
        }
    }, [existingRelationship.dataUpdatedAt, pipelineNode, relationshipId]);

    useEffect(() => {
        if (!nodes.data) {
            return;
        }

        if (!otherPipelineNodeId) {
            return;
        }

        let child: PipelineNode|undefined = undefined;
        let parent: PipelineNode|undefined = undefined;

        if (relationshipType == 'HAS_MANY') {
            child = nodes.data.find(n => n.id === otherPipelineNodeId);
            parent = nodes.data.find(n => n.id === pipelineNode.id);
        } else {
            child = nodes.data.find(n => n.id === pipelineNode.id);
            parent = nodes.data.find(n => n.id === otherPipelineNodeId);
        }

        if (child && parent) {
            setChildName(child.label);
            setParentName(parent.label);

            setChildColumnOptions(child.fields.map(f => {
                return {
                    value: f.id,
                    label: f.label,
                    badgeText: f.type,
                    description: f.description,
                }
            }));

            setParentColumnOptions(parent.fields.map(f => {
                return {
                    value: f.id,
                    label: f.label,
                    badgeText: f.type,
                    description: f.description,
                }
            }));
            

        }
    }, [nodes.dataUpdatedAt, pipelineNode.id, otherPipelineNodeId, relationshipType])
    
    const saveRelationship = useCallback(async (skipOnSave?: boolean) => {
        setPageDirty(false);
        setSavingRelationship(true);
        let childId: string;
        let parentId: string;

        if (relationshipType == 'BELONGS_TO') {
            childId = pipelineNode.id as string;
            parentId = otherPipelineNodeId;
        } else {
            childId = otherPipelineNodeId;
            parentId = pipelineNode.id as string;
        }
        try {
            const newRel = await PipelineNodeRelationshipORM.save({
                id: relationshipId || null,
                child_node_id: childId,
                parent_node_id: parentId,
                parent_lookup_logic_gate: lookupLogicGate,
                parent_lookups: parentLookups,
                denormalized_fields: denormalizedFields,
                name: relationshipName,
                child_foreign_key_name: foreignKeyName,
                description: relationshipDescription,
            });
            invalidatePipelineNodeRelationships();
            invalidatePipelineNodes();
            invalidateMissionControlDataFlowData();
            // setPageDirty(false);

            // If we navigate in the same tick as setting the page dirty to False,
            // then the route blocker will still kick in. So we have to do
            // the save here on the next tick.
            if (!skipOnSave && onSave) {
                setTimeout(() => {
                    onSave(newRel.id as string);
                })
            }
            
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSavingRelationship(false);
        }
    }, [otherPipelineNodeId, denormalizedFields, relationshipId, pipelineNode.id, relationshipType, onSave, parentLookups, relationshipName, relationshipDescription, foreignKeyName, lookupLogicGate]);

    const inDraftMode = useIsInDraftMode();
    
    const {pageDirty, setPageDirty} = useRouteBlocker(() => {
        saveRelationship(true)
    });

    return <Pane>
        <PaneContent>
            <div className="ps-2">
                <div className="mb-2 d-flex center-vertically">
                    <h2 className="mb-0 flex-1">
                        {relationshipId ? 'Configure' : 'New'} Relationship
                    </h2>
                    <DraftOnly>
                        {relationshipId && (
                            <button onClick={deleteRelationship} className="btn btn-danger btn-sm">
                                <i className="mdi mdi-delete"></i> Delete
                            </button>
                        )}
                    </DraftOnly>
                    
                    
                </div>
                
                
                
                <Form.Group className="mb-2">
                    <Form.Label className="small">
                        Name (Optional)
                    </Form.Label>
                    <Form.Control disabled={!inDraftMode} value={relationshipName} onChange={(e) => {
                        setPageDirty(true);
                        setRelationshipName(e.target.value)
                     }} />
                </Form.Group>
                
                <Form.Group className="mb-2">
                    <Form.Label className="small">
                        Description (Optional)
                    </Form.Label>
                    <Form.Control disabled={!inDraftMode} as="textarea" value={relationshipDescription} onChange={(e) => {
                        setPageDirty(true);
                        setRelationshipDescription(e.target.value)
                    }} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label className="small">
                        Relationship Type
                    </Form.Label>
                    <Dropdown
                        disabled={!inDraftMode}
                        onChange={(newVal => {
                            setPageDirty(true);
                            setRelationshipType(newVal);
                        })}
                        selected={relationshipType}
                        options={[{
                            value: 'BELONGS_TO',
                            label: 'Belongs To'
                        }, {
                            value: 'HAS_MANY',
                            label: 'Has Many'
                        }]}
                    />
                </Form.Group>
                <Form.Group>

                    <Form.Label className="small">Related node</Form.Label>
                    <PipelineNodeSelector
                        disabled={!inDraftMode}
                        selectedId={otherPipelineNodeId}
                        blacklist={[pipelineNode.id as string]}
                        onSelect={(bo: PipelineNode | undefined) => {
                            setPageDirty(true);
                            setOtherPipelineNodeId(bo ? bo.id as string : '');
                        }}
                        optionFilter={(opt: PipelineNode) => {
                            return isValidRelationshipTarget(pipelineNode, opt);
                        }}
                    />
                </Form.Group>
            </div>
            <div className="mt-2">
            <h2 className="sidebar-section-header">
                Match Criteria
            </h2>
            <ParentLookupComponent
                childName={childName}
                parentName={parentName}
                logicGate={lookupLogicGate}
                parentLookups={parentLookups}
                onChangeLogicGate={setLookupLogicGate}
                onChangeParentLookups={(lookups => {
                    setPageDirty(true);
                    setParentLookups(lookups);
                })}
                childColumnOptions={childColumnOptions}
                parentColumnOptions={parentColumnOptions}
            />
            </div>
            
            

            <div className="mt-2 mb-2">
            <h2 className="sidebar-section-header">
                Parent Lookup Fields
            </h2>
                <DenormalizedFieldComponent
                    parentColumnOptions={parentColumnOptions}
                    onChangeDenormalizedFields={(newFields => {
                        setPageDirty(true);
                        setDenormalizedFields(newFields);
                    })}
                    denormalizedFields={denormalizedFields}
                    parentName={parentName}
                    childName={childName}
                />
            </div>

            
                
            

        </PaneContent>
        <DraftOnly>
            
            <PaneFooter>
            <AsyncButton
                    className="w-100"
                    variant="pliable"
                    disabled={!otherPipelineNodeId || !parentLookups || !parentLookups.length}
                    loading={savingRelationship}
                    text="Save Relationship"
                    onClick={saveRelationship}
                />
            </PaneFooter>
        </DraftOnly>
    </Pane>
}

export default PipelineNodeRelationshipEditor;