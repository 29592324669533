import RecordInfo from "@components/card/RecordInfo.component";
import SectionHeader from "@components/card/SectionHeader.component";
import Warning from "@components/statusIndicators/Warning.component";
import { PipelineNodeField } from "@models/pipelineNode";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import { formatPercentage, summarizeNumber } from "@services/formatting.service";
import { useColumnShape, useColumnValueDistribution, usePipelineNode } from "@stores/data.store";
import { useMemo } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";

interface ColumnRecordInfoProps {
    pipelineNodeId: string;
    columnId: string;
}

export const ColumnRecordInfo = (props: ColumnRecordInfoProps) => {
    const shapeData = useColumnShape(props.pipelineNodeId, props.columnId);
    const theNode = usePipelineNode(props.pipelineNodeId);

    const theField = useMemo(() => {
        if (!theNode.data) {
            return undefined;
        }

        return theNode.data.fields.find(f => f.id === props.columnId);
    }, [theNode.dataUpdatedAt, props.columnId]);
    const title = useMemo(() => {
        if (!theNode.data || !theField) {
            return '';
        }

        return theNode.data.label + ': ' + theField.label;
    }, [theField, theNode.dataUpdatedAt]);

    const stats = useMemo(() => {
        if (!shapeData.data) {
            return [{
                label: 'Density',
                icon: 'mdi mdi-loading mdi-spin',
                value: ''
            }, {
                label: 'Uniqueness',
                icon: 'mdi mdi-loading mdi-spin',
                value: ''
            }]
        }

        return [{
            label: 'Density',
            icon: 'mdi mdi-water',
            value: formatPercentage(shapeData.data.density)
        }, {
            label: 'Uniqueness',
            icon: 'mdi mdi-hexagram',
            value: formatPercentage(shapeData.data.uniqueness)
        }];
    }, [shapeData.dataUpdatedAt])
    

    return <RecordInfo
        title={title}
        iconComponent={<></>}
        description={theField?.description}
        badges={<></>}
        stats={stats}


    />
    
}

interface ColumnDistroProps {
    pipelineNodeId: string;
    columnId: string;
}

export const ColumnDistro = (props: ColumnDistroProps) => {
    const data = useColumnValueDistribution(props.pipelineNodeId, props.columnId);

    const pn = usePipelineNode(props.pipelineNodeId);

    const totalRecords = useMemo(() => {
        if (!pn.data || !pn.data.shape) {
            return 1;
        }
        return pn.data.shape.total_records;
    }, [pn.dataUpdatedAt]);

    if (data.isLoading || pn.isLoading) {
        return <>
            <i className="mdi mdi-loading mdi-spin"></i> Loading samples
        </>
    }

    return <table className="table">
        <thead className="bg-light">
            <tr>
                <th>Value</th>
                <th># of Occurrences</th>
                <th>% of Total</th>
            </tr>
        </thead>
        <tbody>
            {data.data?.map(d => {
                return <tr>
                    <th>{d.value}</th>
                    <td>{summarizeNumber(d.total)}</td>
                    <td>{formatPercentage(d.total / totalRecords)}</td>
                </tr>
            })}
        </tbody>
    </table>
}

interface Props {
    pipelineNodeId: string;
    column?: PipelineNodeField;
    fieldId?: string;
    show: boolean;
    onHide: () => any;
}

const PipelineNodeColumnDrawer = (props: Props) => {
    const pn = usePipelineNode(props.pipelineNodeId);

    const column = useMemo(() => {
        if (!pn.data) {
            return undefined;
        }

        if (props.column) {
            return props.column;
        } else if (props.fieldId) {
            return pn.data.fields.find(f => f.id === props.fieldId);
        }
        return undefined;
    }, [props.column, props.fieldId, pn.dataUpdatedAt]);

    return <Offcanvas placement="end" show={props.show} onHide={props.onHide}>
    <Offcanvas.Header closeButton>
        <Offcanvas.Title>Column Details</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
        {pn.data && !pn.data.shape && (
            <Pane>
                <div className="p-3">
                    <Warning>
                        <div>
                            This node hasn't been built yet!<br />
                            <Link to={`/node/${props.pipelineNodeId}/config`}>Build</Link>
                        </div>
                        
                    </Warning>
                </div>
            </Pane>
        )}
        {pn.data && column && pn.data.shape && (
            <Pane>
                <div className="p-3">
                    <ColumnRecordInfo
                        pipelineNodeId={props.pipelineNodeId}
                        columnId={column.id}
                    />
                    
                </div>
                
                <SectionHeader>
                    Value Distribution
                
                </SectionHeader>
                <PaneContent>

                <div className="p-3">
                <ColumnDistro pipelineNodeId={props.pipelineNodeId} columnId={column.id}/>
                
                </div>
                    
                
                </PaneContent>
            </Pane>
                
               
        )}
        
    </Offcanvas.Body>
</Offcanvas>
}

export default PipelineNodeColumnDrawer;