import Dropdown from "@components/form/Dropdown.component";
import { PipelineNode, PipelineNodeORM } from "@models/pipelineNode";
import { getErrorMessage } from "@services/errors.service";
import { getNodeColor, getNodeTypeConfig } from "@services/modeling.service";
import toast from "@services/toast.service";
import { usePipelineNodes } from "@stores/data.store";
import { useCallback, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface Props {
    selectedId: string;
    disabled?: boolean;
    onSelect: (pipelineNode: PipelineNode|undefined) => void;
    blacklist?: string[];
    optionFilter?: (pn: PipelineNode) => boolean;
    debugOptions?: boolean;
}

const PipelineNodeSelector = (props: Props) => {
    const pipelineNodes = usePipelineNodes();
    const [backgroundLoading, setBackgroundLoading] = useState(false);

    const filterfunc = useCallback((pn: PipelineNode) => {
        if (props.blacklist && props.blacklist.includes(pn.id as string)) {
            return false;
        }
        if (props.optionFilter && !props.optionFilter(pn)) {
            return false;
        }

        return true;
    }, [props.optionFilter, props.blacklist]);

    const options = useMemo(() => {
        if (pipelineNodes.data) {
            return pipelineNodes.data.filter(filterfunc).map(node => {
                const ntg = getNodeTypeConfig(node);
                return {
                    label: node.label,
                    description: node.description,
                    value: node.id as string,
                    badgeText: ntg.nodeType.label,
                    badgeVariant: getNodeColor(node),
                }
            }).sort((a, b) => {
                return a.label.localeCompare(b.label);
            })
        }
        return [];
    }, [pipelineNodes.dataUpdatedAt, props.blacklist, filterfunc]);

    const onSelect = useCallback(async (opt: string) => {
        if (!opt) {
            props.onSelect(undefined);
        } else {
            props.onSelect(pipelineNodes.data?.find(b => b.id === opt));
        }
    }, [pipelineNodes.dataUpdatedAt]);

    let wrapperClass = '';

    const onCreate = useCallback(async (newName: string) => {
        const bo = await PipelineNodeORM.save({
            id: null,
            name: newName,
            label: newName,
            description: '',
            node_type: 'STAGING',
            upstream_node_ids: [],
            fields: [],
            table_name: '',
            flat_file: false
        });

        props.onSelect(bo);

    }, [props.onSelect]);

    return <div className={wrapperClass}>
        {pipelineNodes.isLoading && (
            <Spinner size="sm"/>
        )}
        {!pipelineNodes.isLoading && (
            <>
                {props.debugOptions && JSON.stringify(options)}
                <Dropdown
                    disabled={!!props.disabled}
                    onCreateOption={onCreate}
                    placeholder="Select node"
                    options={options}
                    selected={props.selectedId}
                    onChange={onSelect}
                />
            </>

            
            
        )}

    </div>
}

export default PipelineNodeSelector;