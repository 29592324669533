
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Overlay, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import TrackingService, {Events} from "@services/tracking.service";
import styled from 'styled-components';
import produce from "immer";
import Dropdown, { Option as DropdownOption } from "@components/form/Dropdown.component";
import { DOMContainer } from "@restart/ui/esm/useWaitForDOMRef";
import { Updater, useImmer } from "use-immer";
import { useDebouncedCallback } from "use-debounce";
import { DataWhitelist, DataWhitelistEntry, PipelineNodeField } from "@models/pipelineNode";
import Editor from "@monaco-editor/react";
import { DraftOnly } from "@components/project/DraftModeRequired.component";
import { useIsInDraftMode, usePipelineNodes } from "@stores/data.store";


export const comparatorOptions = [
    {
        label: 'is equal to',
        value: 'EQUALS',
    }, 
    {
        label: 'is not equal to',
        value: 'NOT_EQUALS',
    },
    {
        label: 'contains',
        value: 'CONTAINS',
    },
    {
        label: 'does not contain',
        value: 'NOT_CONTAINS',
    },
    {
        label: 'is greater than',
        value: 'GT',
    }, {
        label: 'is greater than or equal to',
        value: 'GTE',
    }, {
        label: 'is less than',
        value: 'LT',
    }, {
        label: 'is less than or equal to',
        value: 'LTE',
    }, {
        label: 'is blank',
        value: 'EMPTY',
    }, {
        label: 'is not blank',
        value: 'NOT_EMPTY',
    },{
        label: 'starts with',
        value: 'STARTS_WITH',
    },{
        label: 'does not start with',
        value: 'NOT_STARTS_WITH',
    },{
        label: 'ends with',
        value: 'ENDS_WITH',
    },{
        label: 'does not end with',
        value: 'NOT_ENDS_WITH',
    }, {
        label: 'is not a valid date',
        value: 'INVALID_DATE',
    }, {
        label: 'is a valid date',
        value: 'VALID_DATE',
    }, {
        label: 'is a valid number',
        value: 'VALID_NUMBER',
    }, {
        label: 'is not a valid number',
        value: 'INVALID_NUMBER',
    }
];

const comparatorsWithValues = [
    'EQUALS', 'NOT_EQUALS', 'CONTAINS', 'NOT_CONTAINS', 'GT', 'GTE', 'LT', 'LTE', 'STARTS_WITH', 'NOT_STARTS_WITH', 'ENDS_WITH', 'NOT_ENDS_WITH'
];


const EntryForm = styled.div`
width: 100%;
`

const SingleEntryStyles = styled.div`
    background-color: #eef2f7;
    color: #313a46;
    border-radius: 5px;
    padding: .5rem;
    margin-bottom: .5rem;
    display: flex;

    &:last-child {
        margin-bottom: 0px;
    }

    &:not(.highlight):hover {
        background-color: white;
        color: black;
        cursor: pointer;
    }

    &.highlight {
        outline: solid 2px var(--pliable-yellow);


    }
`

interface SingleEntryProps {
    entry: DataWhitelistEntry;
    columnOptions: DropdownOption[];
   
    onClick: () => void;
    onRemove: () => void;
    highlight: boolean;
}

const SingleEntry = ( props : SingleEntryProps) => {
    const columnName = useMemo(() => {
        if (props.columnOptions.length == 0 || !props.entry.field_id || !props.entry.pipeline_node_id) {
            return '[none]';
        }

        const selectedValue = `${props.entry.pipeline_node_id}:${props.entry.field_id}`;

        
        let rv = `${props.columnOptions.find(co => co.value === selectedValue)?.label}`;


        return rv;
    }, [props.columnOptions, props.entry.field_id, props.entry.pipeline_node_id]);

    const value = useMemo(() => {
        if (!comparatorsWithValues.includes(props.entry.comparator)) {
            return '';
        }
        
        return `"${props.entry.value}"`;
    }, [props.entry.comparator, props.entry.value]);

    const comparator = useMemo(() => {
        return comparatorOptions.find(c => c.value === props.entry.comparator)?.label;

    }, [props.entry.comparator]);
    const className = useMemo(() => {
        if (props.highlight) {
            return 'highlight';
        }
        return ''
    }, [props.highlight])
    return <SingleEntryStyles className={className} onClick={() => props.onClick()}>
        <div className="flex-1">
            <strong>{columnName}</strong> <span>{comparator}</span> <strong>{value}</strong>
        </div>
        
        <DraftOnly>
            <span>
                <button onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    props.onRemove()
            }} className="icon-button"><i className="mdi mdi-close-thick"></i></button>
            </span>
        </DraftOnly>
       

    </SingleEntryStyles>
}

interface ColumnOption {
    pipelineNodeId: string;
    fieldId: string;
    fieldLabel: string;
    nodeLabel: string;
    fieldDescription?: string;
}

interface Props {
    config: DataWhitelist;
    nodeIds: string[];
    onChange: (whitelist: DataWhitelist) => void;
}



const logicGateOptions = [
    {
        label: 'Match ALL',
        description: 'Find records that match all of the filters below',
        value: 'AND',
    }, {
        label: 'Match ANY',
        description: 'Find records that match at least one of the filters below',
        value: 'OR',
    }, {
        label: 'Custom SQL',
        description: 'Write custom SQL for more advanced filtering options',
        value: 'CUSTOM',
    }
]

const EditorStyles = styled.div`
.monaco-editor .suggest-widget { 
    width: 280px !important; 
    left: 0px !important;
}
`
const DataWhitelistForm = (props: Props) => {

    const nodes = usePipelineNodes();

    const availableColumns: ColumnOption[] = useMemo(() => {
        if (!nodes.data) {
            return [];
        }

        return nodes.data.filter(n => props.nodeIds.includes(n.id as string)).map(n => {
            return n.fields.map(f => {
                return {
                    pipelineNodeId: n.id as string,
                    fieldId: f.id,
                    fieldLabel: f.label,
                    fieldDescription: f.description,
                    nodeLabel: n.label,
                }
            })
        }).flat()
    }, [nodes.dataUpdatedAt, props.nodeIds]);

    const dropdownOptions: DropdownOption[] = useMemo(() => {
        return availableColumns.map(ac => {
            return {
                value: `${ac.pipelineNodeId}:${ac.fieldId}`,
                label: `${ac.nodeLabel} - ${ac.fieldLabel}`,
                description: ac.fieldDescription,
            };
        });
    }, [availableColumns]);


    const [formData, setFormData] = useImmer<DataWhitelist>({
        entries: [],
        logic_gate: 'AND',
    });

    const incrementFilterIdx = () => {
        setEditingEntryIndex(editingEntryIndex + 1)
    }

    const decrementFilterIdx = () => {
        setEditingEntryIndex(editingEntryIndex + -1)
    }

    const changedEntryValue = useCallback((idx: number, newVal: string) => {
        props.onChange(produce(props.config, draft => {
            draft.entries[idx].value = newVal;
        }))
       
    }, [props.onChange, props.config]);

    const changedEntryColumn = useCallback((idx: number, newVal: string) => {
        if (newVal) {
            const [pipelineNodeId, fieldId] = newVal.split(':');
            props.onChange(produce(props.config, draft => {
                draft.entries[idx].pipeline_node_id = pipelineNodeId;
                draft.entries[idx].field_id = fieldId;
            }));
        } else {
            props.onChange(produce(props.config, draft => {
                draft.entries[idx].pipeline_node_id = '';
                draft.entries[idx].field_id = '';
            }));
        }
        
    }, [props.onChange, props.config]);

    const changedEntryComparator = useCallback((idx: number, comparator: string) => {
        props.onChange(produce(props.config, draft => {
            draft.entries[idx].comparator = comparator;
        }));
    }, [props.onChange, props.config]);
    
    const [editingEntryIndex, setEditingEntryIndex] = useState(-1);

    const addEntry = useCallback(() => {
        props.onChange(produce(props.config, draft => {
            draft.entries.push({
                value: '',
                comparator: 'EQUALS',
                pipeline_node_id: '',
                field_id: '',
            });
        }));
        
        incrementFilterIdx();

    }, [props.config, props.onChange, incrementFilterIdx, props.config]);

    const removeEntry = useCallback((idx: number) => {
        props.onChange(produce(props.config, draft => {
            draft.entries.splice(idx, 1);
        }));
       
        if (editingEntryIndex == idx) {
            setEditingEntryIndex(-1);
        } else if (editingEntryIndex > idx) {
            decrementFilterIdx();
        }
    }, [props.config, props.onChange, editingEntryIndex]);

    

    const changeLogicGate = useCallback((gate: string) => {
        props.onChange(produce(props.config, draft => {
            draft.logic_gate = gate;
        }));
    }, [props.onChange, props.config]);
   

    const inDraftMode = useIsInDraftMode();

    return <div>
            <Dropdown
                disabled={!inDraftMode}
                options={logicGateOptions}
                selected={props.config.logic_gate}
                onChange={changeLogicGate}
                className="mb-2"
            />

            {props.config.logic_gate === 'CUSTOM' && (
                <textarea className="form-control" value={props.config.custom_sql} onChange={(e) => {
                    props.onChange(produce(props.config, draft => {
                        draft.custom_sql = e.target.value;
                    }));
                }}></textarea>
            )}

            {props.config.logic_gate !== 'CUSTOM' && <>
                {props.config.entries.length === 0 && (
                    <div>No whitelist rules (matching all records)</div>
                )}
                {props.config.entries.map((f, idx) =>
                    <SingleEntry 
                        highlight={idx === editingEntryIndex} 
                        entry={f} 
                        columnOptions={dropdownOptions}  
                        onClick={() => setEditingEntryIndex(idx)} 
                        onRemove={() => removeEntry(idx)}
                    />
                )}
                    
                            
                            
                {editingEntryIndex >= 0 && props.config.entries[editingEntryIndex] && (
                    <EntryForm className="shadow-box mt-2 p-2">
                        <Form.Group className="mb-2">
                            <Form.Label className="small">Select Column</Form.Label>
                            {dropdownOptions.length > 0 && (
                                <Dropdown
                                    disabled={!inDraftMode}
                                    allowEmpty
                                    options={dropdownOptions}
                                    selected={`${props.config.entries[editingEntryIndex].pipeline_node_id}:${props.config.entries[editingEntryIndex].field_id}`}
                                    onChange={(newVal) => {
                                        changedEntryColumn(editingEntryIndex, newVal);
                                    }}
                                />
                            )}
                            
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label className="small">Comparator</Form.Label>
                            <Dropdown
                                disabled={!inDraftMode}
                                options={comparatorOptions}
                                
                                selected={props.config.entries[editingEntryIndex].comparator}
                                onChange={(newVal) => {
                                    changedEntryComparator(editingEntryIndex, newVal);
                                }}
                            />
                        </Form.Group>
                        
                        {comparatorsWithValues.includes(props.config.entries[editingEntryIndex].comparator) && (
                            <Form.Group className="mb-2">
                                <Form.Label className="small">Value</Form.Label>
                                <Form.Control disabled={!inDraftMode} placeholder="enter value" type="text" className="match-with-dropdown" value={props.config.entries[editingEntryIndex].value} onChange={(e) => changedEntryValue(editingEntryIndex, e.target.value)}/>
                            </Form.Group>
                        )}
                        
                        <button className="btn btn-sm btn-light" onClick={() => setEditingEntryIndex(-1)}>Done Editing</button>
                        
                    </EntryForm>
                )}
                <DraftOnly>
                    <button onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        addEntry();
                    }}  className="icon-button my-2">
                        <i className="mdi mdi-plus-circle"></i> Add Rule
                    </button>
                </DraftOnly>
            </>}
            
            
            
            
        </div>
}

export default DataWhitelistForm;
